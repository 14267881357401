export const BluData = {
        "companyName": "BLU",
        "logoUrl": "",
        "eventsNearYou": true,
        "createFlyer": true,
        "joinEvents": true,
        "followProfile": true,
        "privacy": true,
        "buyTickets": true,
        "postClips": true,
        "iNCrowdFund": true,
        "iNMsg": true,
        "mobileFirst": true
}

export const competitorData = [
    {
        "companyName": "EB",
        "logoUrl": "https://cdn.evbstatic.com/s3-build/perm_001/477279/django/images/favicons/favicon-194x194.png",
        "eventsNearYou": false,
        "createFlyer": false,
        "joinEvents": true,
        "followProfile": true,
        "privacy": true,
        "buyTickets": true,
        "iNCrowdFund": false,
        "iNMsg": false,
        "mobileFirst": false
    },
    {
        "companyName": "MU",
        "logoUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6b/Meetup_Logo.png",
        "eventsNearYou": false,
        "createFlyer": false,
        "joinEvents": true,
        "followProfile": true,
        "privacy": true,
        "buyTickets": false,
        "iNCrowdFund": false,
        "iNMsg": true,
        "mobileFirst": false
}
]
    