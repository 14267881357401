export const OurGoals = () => {
  return (
    <section id="ourGoals" className="ourGoals">
      <video
        src="https://firebasestorage.googleapis.com/v0/b/bringinglinkups.appspot.com/o/BLU_Intro_1.mp4?alt=media&token=650291c8-ac0a-4240-910c-5a91b619cfe9"
        className="solutionVideo"
        controls
        loop
        width={"90%"}
      />
    </section>
  );
};
